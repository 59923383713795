import axios from "axios";

// Company Data
export const PostCompany = async () => {
    try {
        const requestData = {
            find_type: "domain",
            find_value: 'machhub-company.vercel.app'
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };

        const { data } = await axios.post('/api/front/company/find', requestData, config);

        return { data };

    } catch (error) {
        console.log("Error:", error.message);
        throw new Error(error.message);
    }
};

// Restaurant Data
export const PostRestaurant = async (slug) => {
    try {
        const requestData = {
            find_type: "slug",
            find_value: slug,
            domain: 'machhub-company.vercel.app'
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };

        const { data } = await axios.post('/api/front/restaurant/find', requestData, config);

        return { data };

    } catch (error) {
        console.log("Error:", error.message);
        throw new Error(error.message);
    }
};

// Checkout Data
export const PostCheckout = async (restaurantId, basketData, type) => {
    try {
        const requestData = {
            restaurant_id: restaurantId,
            type: type,
            products: basketData,
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };

        const { data } = await axios.post('/api/front/order/calculate-cart', requestData, config);

        return { data };

    } catch (error) {
        console.log("Error:", error.message);
        throw new Error(error.message);
    }
};

// OrderCreate Data
export const PostOrderCreate = async (restaurantId, basketData, type, payment_type, restaurantNote) => {
    try {
        const requestData = {
            restaurant_id: restaurantId,
            customer_id: null, // opsyonel
            customer: null, // opsyonel
            address: "fjldsfjldsjflsdj", // type === 1 ise zorunlu
            payment_type: payment_type,
            type: type,
            note: restaurantNote,
            is_pre_order: "qui",
            products: basketData,
            coupon_code: null, // opsyonel
            use_point: null, // opsyonel
            table: null, // opsyonel
            orderDate: null // opsyonel
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };

        const { data } = await axios.post('/api/front/order/create', requestData, config);

        return { data };

    } catch (error) {
        console.log("Error:", error.message);
        throw new Error(error.message);
    }
};

export const AutoComplete = async (searchKeyword) => {
    try {
        const requestData = {
            q: searchKeyword
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };

        const { data } = await axios.post('api/map/autocomplete', requestData, config);

        return { data };

    } catch (error) {
        console.log("Error:", error.message);
        throw new Error(error.message);
    }
};

export const getPlaceByPlaceID = async (placeId) => {
    try {
        const requestData = {
            place_id: placeId
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };

        const { data } = await axios.post('api/map/getPlaceByPlaceID', requestData, config);

        return { data };

    } catch (error) {
        console.log("Error:", error.message);
        throw new Error(error.message);
    }
}

