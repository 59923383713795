import React, { useEffect, useState } from 'react';
import { FaWalking } from "react-icons/fa";
import { IoBicycle } from "react-icons/io5";
import { FaClock } from "react-icons/fa";
import { AiFillMessage } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { BiMobileVibration } from "react-icons/bi";
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { AnimatePresence, motion } from 'framer-motion';
import { AutoComplete, PostCheckout, PostOrderCreate } from '../../services';
import { useForm } from "react-hook-form";

const CheckoutPage = () => {
    let checkoutData;
    const location = useLocation();
    const navigate = useNavigate();
    const basketData = JSON.parse(window.localStorage.getItem('basket'));
    const { restaurantId, type, restaurantName, restaurantAddres } = location.state || {};
    const [aftDel, setAftDel] = useState(type);
    const [messageOpen, setMessageOpen] = useState(false);
    const [restaurantNote, setRestaurantNote] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [suggestionVisable, setSuggestionVisable] = useState(false);
    const [placeId, setPlaceId] = useState('');

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
        },
        mode: "onChange",
    });

    const onSubmit = (data) => {
        console.log(data);
    };

    useEffect(() => {
        if (!location.state || !localStorage.getItem('basket')) {
            return navigate('/');
        }
    });

    const { data: autoCompleteData, isFetching, refetch } = useQuery({
        queryFn: () => AutoComplete(searchKeyword === '' ? 'dan' : searchKeyword),
        queryKey: ['autoComplate'],
        enabled: false,
    });

    const { data: placeData, isFetching: fetch, refetch: refetchFetch } = useQuery({
        queryFn: () => AutoComplete(placeId),
        queryKey: ['autoComplatePlace'],
        enabled: false,
    });

    useEffect(() => {
        const inputElement = document.getElementById('address');

        if (inputElement) {
            const handleInputChange = (e) => {
                if (!isFetching) {
                    refetch();
                }
            };

            inputElement.addEventListener('input', handleInputChange);

            return () => {
                inputElement.removeEventListener('input', handleInputChange);
            };
        } else {
            return;
        }
    }, [searchKeyword]);

    useEffect(() => {
        const inputElement = document.getElementById(placeId);

        if (inputElement) {
            if (!fetch) {
                refetchFetch();
            }
        } else {
            return;
        }
    }, [placeId]);


    const { data, isLoading, error } = useQuery({
        queryFn: () => PostCheckout(restaurantId, basketData, type),
        queryKey: ['checkoutData'],
        onError: (error) => {
            toast.error(error.message);
            console.log(error);
        }
    });
    if (isLoading) {
        return <p>Loading...</p>;
    } if (error) {
        console.error("Error fetching data:", error);
        return <p>Error fetching data</p>;
    } if (data) {
        checkoutData = data.data;
    }

    const Submit = async (payment_type) => {
        try {
            const { data } = await PostOrderCreate(restaurantId, basketData, aftDel, payment_type, restaurantNote);
            console.log(data);
        } catch (error) {
            toast.error(error.message);
            console.log(error);
        }
    };

    const suggestionItemClickFunc = (desc, place_id) => {
        setSearchKeyword(desc);
        setPlaceId(place_id);
    };

    return (
        <motion.div className="CheckoutPage-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
        >
            <div className="checkout-container">
                <div className="checkout">
                    <h1 className="title">Checkout</h1>

                    <div className="content">
                        <div className="title-container">
                            <h3 className="content-title">{restaurantName}</h3>
                            <p className="subtitle">{restaurantAddres.detail}</p>
                        </div>

                        <div className="order-type sec">
                            <h3 className="content-title">Ordretype og tid</h3>
                            <div className="btn-container">
                                <button className={`detail-btn ${aftDel === 1 && 'pasive'}`} onClick={() => setAftDel(0)}>
                                    <FaWalking />
                                    Afhentning
                                </button>
                                <button className={`detail-btn ${aftDel === 0 && 'pasive'}`} onClick={() => setAftDel(1)}>
                                    <IoBicycle />
                                    Leverning
                                </button>
                            </div>
                            <button className="clock-btn"><FaClock />i dag kl. 11:06</button>
                        </div>

                        <div className="details sec">
                            <h3 className="content-title">Dine detaljer</h3>
                            <form className="details-form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-item">
                                    <div className="form_item_content">
                                        <label htmlFor="firstName" className='form-label'>Fornavn <span className="compulsory">*</span></label>
                                        {errors.firstName?.message && (<p className="error_message">{errors.firstName?.message}</p>)}
                                    </div>
                                    <input type="text" id='firstName' className='form-txt' placeholder='Fornavn' required="required"
                                        {...register('firstName', {
                                            minLength: {
                                                value: 1,
                                                message: "Name length must be at least 1 character",
                                            },
                                            required: {
                                                value: true,
                                                message: "Name is required",
                                            },
                                        })}
                                    />
                                </div>

                                <div className="form-item">
                                    <div className="form_item_content">
                                        <label htmlFor="lastName" className='form-label'>Efternavn <span className="compulsory">*</span></label>
                                        {errors.lastName?.message && (<p className="error_message">{errors.lastName?.message}</p>)}
                                    </div>
                                    <input type="text" id='lastName' className='form-txt' placeholder='Efternavn' required="required"
                                        {...register('lastName', {
                                            minLength: {
                                                value: 1,
                                                message: "Name length must be at least 1 character",
                                            },
                                            required: {
                                                value: true,
                                                message: "Name is required",
                                            },
                                        })}
                                    />
                                </div>

                                <div className="form-item">
                                    <div className="form_item_content">
                                        <label htmlFor="email" className='form-label'>E-mailadresse <span className="compulsory">*</span></label>
                                        {errors.email?.message && (<p className="error_message">{errors.email?.message}</p>)}
                                    </div>
                                    <input type="text" id='email' className='form-txt' placeholder='E-mailadresse'
                                        {...register("email", {
                                            pattern: {
                                                value:
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: "Enter a valid email",
                                            },
                                            required: {
                                                value: true,
                                                message: "Email is required",
                                            },
                                        })}
                                    />
                                </div>

                                <div className="form-item">
                                    <div className="form_item_content">
                                        <label htmlFor="phoneNumber" className='form-label'>Telefon <span className="compulsory">*</span></label>
                                        {errors.phoneNumber?.message && (<p className="error_message">{errors.phoneNumber?.message}</p>)}
                                    </div>
                                    <input type="text" id='phoneNumber' className='form-txt' placeholder='Telefon'
                                        {...register("phoneNumber", {
                                            pattern: {
                                                value: /^(\+?\d{1,4}[\s-]?)?\(?\d{1,4}\)?[\s-]?\d{1,9}$/,
                                                message: "Enter a valid number",
                                            },
                                            required: {
                                                value: true,
                                                message: "Number is required",
                                            },
                                        })}
                                    />

                                </div>

                                <AnimatePresence>
                                    {aftDel === 1 &&
                                        <motion.div className="form-item"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <label htmlFor="address" className='form-label'>Adresse <span className="compulsory">*</span></label>
                                            <input type="text" id='address' className='form-txt' placeholder='Addresse' value={searchKeyword} required onChange={(e) => setSearchKeyword(e.target.value)} onFocus={() => setSuggestionVisable(true)} onBlur={() => setTimeout(() => { setSuggestionVisable(false); }, 200)} />
                                            <AnimatePresence>
                                                {suggestionVisable &&
                                                    <motion.ul className="suggestions"
                                                        initial={{ opacity: 0, height: 0, y: "-50%", scaleY: 0 }}
                                                        animate={{ opacity: 1, height: "auto", y: 0, scaleY: 1 }}
                                                        exit={{ opacity: 0, height: 0, y: "-50%", scaleY: 0, transition: { duration: 0.2 } }}
                                                        transition={{ type: "spring", duration: 0.7 }}
                                                    >
                                                        {isFetching && <li className='suggestion_item'>Loading...</li>}
                                                        {!isFetching && autoCompleteData?.data?.length > 0 && autoCompleteData?.data?.map((completeItem, completeItemKey) => (
                                                            <li className="suggestion_item" id={completeItem.place_id} key={completeItemKey} onClick={() => suggestionItemClickFunc(completeItem.description, completeItem.place_id)}>
                                                                {completeItem.description}
                                                            </li>
                                                        ))}
                                                    </motion.ul>
                                                }
                                            </AnimatePresence>
                                        </motion.div>}
                                </AnimatePresence>
                            </form>
                        </div>

                        <div className="products sec">
                            <h3 className="content-title">Produkter</h3>
                            <ul className="product-list">
                                {basketData.map((basketItem, basketItemKey) => (
                                    <li className="product-list-item" key={basketItemKey}>
                                        {basketItem.name}

                                        <div className='product_option'>
                                            {basketItem.variants[0]?.option.name}

                                            {basketItem.extras?.map((extra, extraKey) => (
                                                <div className='product_extra' key={extraKey}>{extra.name}</div>
                                            ))}
                                        </div>

                                    </li>
                                ))}
                            </ul>

                            <div className="product-message-box" onClick={() => setMessageOpen((p) => !p)}>
                                <div className="message-title-container">
                                    <AiFillMessage />
                                    <h5 className="message-title">Tlfej kommentar till restaurant</h5>
                                </div>
                                <p className="title-content">Informer on allerganer, specielle foresporgsler og mere</p>
                                <IoIosArrowDown className='arrow-icon' />
                            </div>
                            <AnimatePresence>
                                {messageOpen && <motion.textarea id="noteContent" className='restaurant_note' maxLength='150' resize='none' rows="3" cols="50" placeholder="Enter your note here..." value={restaurantNote} onChange={(e) => setRestaurantNote(e.target.value)}
                                    initial={{ opacity: 0, height: 0, y: "-50%", scaleY: 0 }}
                                    animate={{ opacity: 1, height: "100%", y: 0, scaleY: 1 }}
                                    exit={{ opacity: 0, height: 0, y: "-50%", scaleY: 0, transition: { duration: 0.2 } }}
                                    transition={{ type: "spring", duration: 0.5 }}
                                />}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>

                <div className="payment-card">
                    <div className="price-info">
                        <h2 className="info-title">Total (DKK. inkl. moms)</h2>
                        <ul className="product-list">
                            <ul className="list-item">Subtotal <span className="price">{checkoutData.sub_total} kr.</span></ul>
                            <ul className="list-item">Bag <span className="price">{checkoutData.bag} kr.</span></ul>
                            <ul className="list-item">Payment Fee <span className="price">{checkoutData.payment_fee} kr.</span></ul>
                        </ul>
                        <p className="total-price">Tilbage at betale <span className="price">{checkoutData.total} kr.</span></p>
                    </div>

                    <label htmlFor="condition" className='conditions'>
                        <input type="checkbox" id='condition' />
                        Jog har least og accapterer
                    </label>

                    <button className="pay-button" onClick={() => Submit("MOBİLE")}><BiMobileVibration className='pay-icon' />Mobile Pay</button>

                    <p className="pay-kredit-title">Eller betal med kredit kart</p>

                    <button className="credit-pay-btn" onClick={() => Submit("CARD")}>Betal med kredit kart</button>

                    <p className="pay-kredit-title">Eller betal med cash</p>

                    {aftDel === 0 &&
                        <button className="cash_btn" onClick={() => Submit("RESTAURANT")}>Pay at the Restaurant</button>
                    }
                    {aftDel === 1 &&
                        <button className="cash_btn" onClick={() => Submit("DOOR")}>Pay at the Door</button>
                    }
                </div>
            </div>
        </motion.div >
    );
};

export default CheckoutPage;