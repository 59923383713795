import React, { useState } from 'react';
import { TiThMenu } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";
import { LuMail } from "react-icons/lu";
import { BsShop } from "react-icons/bs";
import { AnimatePresence } from 'framer-motion';
import { motion } from "framer-motion";


const Header = ({ menuOpen, setMenuOpen, restaurantData }) => {
    const [login, setLogin] = useState(false);

    const menuOpenFunc = (e) => {
        e.stopPropagation();
        setMenuOpen((p) => !p);
    };

    return (
        <header className='header' style={menuOpen ? { zIndex: 7000 } : {}}>
            <h2 className="logo">restaurant</h2>

            <nav className="nav_items">
                {login ?
                    (<button className='nav_item'>Opret Profil</button>) :
                    <button className='nav_item'>Log Ind</button>
                }

                {restaurantData && <button className='nav_item' onClick={(e) => menuOpenFunc(e)}><TiThMenu className='icon' /></button>}
            </nav>

            <AnimatePresence>
                {menuOpen &&
                    <motion.div className="header-toggleBar" onClick={(e) => e.stopPropagation()}
                        initial={{ x: 300, opacity: 0.5 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 300, opacity: 0.5 }}
                        transition={{ duration: 0.3 }}
                    >
                        <IoClose className="close-icon" onClick={() => setMenuOpen((p) => !p)} />

                        <h1 className="setting_title">{restaurantData.name}</h1>

                        <div className="toggleBar-content">
                            <h3 className="title">Åbningstider</h3>

                            {restaurantData.settings?.working_hours.restaurant.map((data, key) => (
                                <div className="toggleBar-item-container" key={key} >
                                    <p className='day'>{data.name}:</p>
                                    <span className="hour">{data.hour}</span>
                                </div>
                            ))}
                        </div>

                        <div className="toggleBar-info">
                            <h3 className="title">Kontaktoplysninger</h3>


                            <div className="toggleBar-info-container">
                                <BsTelephone />
                                <span className="info">{restaurantData.phone}</span>
                            </div>
                            <div className="toggleBar-info-container">
                                <LuMail />
                                <span className="info">{restaurantData.company?.contact?.email}</span>
                            </div>
                            <div className="toggleBar-info-container">
                                <BsShop />
                                <span className="info">{restaurantData.address?.detail}</span>
                            </div>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </header >
    );
};

export default Header;