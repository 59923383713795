import React from 'react';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className="footer_content">
                <h3 className="logo">restaurant</h3>
                <div className="footer_item">Kontrolrapport</div>
                <div className="footer_item">Cookies</div>
                <div className="footer_item">Vilkår og betingelser</div>
                <div className="footer_item">Privatlivspolitik</div>
            </div>
        </footer>
    );
};

export default Footer;