import { Routes, Route, useLocation } from "react-router-dom";

// pages
import CompanyPage from "./pages/CompanyPage/CompanyPage";
import RestaurantPage from "./pages/RestaurantPage/RestaurantPage";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
/* import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/LoginPage/RegisterPage"; */

function App() {
  const location = useLocation();

  return (
    <Routes>
      <Route index path='/' element={<CompanyPage />} />
      <Route path="/:slug" element={<RestaurantPage />} />
      <Route key={location.key} path="/checkout" element={<CheckoutPage />} />
      {/* <Route path="/login" element={<LoginPage />} /> */}
      {/* <Route path="/register" element={<RegisterPage />} /> */}
    </Routes>
  );
}

export default App;
