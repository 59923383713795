import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';


import { PostRestaurant } from '../../services';
import MainLayout from '../../components/MainLayout';
import Slider from './components/Slider';
import Navbar from './components/Navbar';
import SideBarLeft from './components/SidebarLeft';
import Menu from "./components/Menu";


const RestaurantPage = () => {
    const { slug } = useParams();
    let restaurantData;
    const [basketMenuToggle, setBasketMenuToggle] = useState(false);
    const [basket, setBasket] = useState(JSON.parse(window.localStorage.getItem('basket')) || []);
    window.localStorage.setItem('basket', JSON.stringify(basket));

    const { data, isLoading, error } = useQuery({
        queryFn: () => PostRestaurant(slug),
        queryKey: ['restaurantData'],
        onError: (error) => {
            toast.error(error.message);
            console.log(error);
        }
    });
    if (isLoading) {
        return <p>Loading...</p>;
    } if (error) {
        console.error("Error fetching data:", error);
        return <p>Error fetching data</p>;
    } if (data) {
        restaurantData = data.data;
    }

    return (
        <MainLayout restaurantData={restaurantData}>
            <div className="restaurant_page" onClick={() => setBasketMenuToggle(false)}>
                <Slider name={restaurantData.name} address={restaurantData.address} />

                <Navbar restaurantName={restaurantData.name} restaurantAddres={restaurantData.address} restaurantId={restaurantData.id} settings={restaurantData.settings} basket={basket} setBasket={setBasket} basketMenuToggle={basketMenuToggle} setBasketMenuToggle={setBasketMenuToggle} />

                <div className="menu_container">
                    <SideBarLeft menu={restaurantData.menu} />

                    <Menu menu={restaurantData.menu} setBasket={setBasket} basketMenuOpen={basketMenuToggle} />
                </div>
            </div>
        </MainLayout >
    );
};

export default RestaurantPage;