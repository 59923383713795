import React, { useState } from 'react';
import { FaStoreAlt } from "react-icons/fa";
import { FaMotorcycle } from "react-icons/fa";
import { LuTimer } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { MdOutlineShoppingBag } from "react-icons/md";
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { motion } from "framer-motion";

const Navbar = ({ restaurantName, restaurantAddres, restaurantId, settings, basket, setBasket, basketMenuToggle, setBasketMenuToggle }) => {
    const [navbarColor, setNavbarColor] = useState(false);
    const [activeNavItem, setActiveNavItem] = useState(0);
    const [basketTotalPrice, setBasketTotalPrice] = useState(0);

    const changeBackground = () => {
        const sliderElement = document.querySelector('.slider');

        if (sliderElement !== null) {
            if (window.scrollY >= sliderElement.clientHeight) {
                setNavbarColor(true);
            } else {
                setNavbarColor(false);
            }
        }
    };
    window.addEventListener("scroll", changeBackground);

    const basketMenuToggleFunc = (e) => {
        setBasketMenuToggle(true);
        setBasketTotalPrice(basket.reduce((basketTotalPrice, basketItem) => basketTotalPrice + basketItem.price * basketItem.qty, 0));
        e.stopPropagation();
    };

    const increaseCount = (key) => {
        setBasket((prevBasket) => prevBasket.map((item, index) => index === key ? { ...item, qty: item.qty + 1 } : item));
        setBasketTotalPrice((prevTotalPrice) => prevTotalPrice + basket[key].price);
    };

    const decreaseCount = (key) => {
        setBasketTotalPrice((prevTotalPrice) => prevTotalPrice - basket[key].price);

        if (basket[key].qty === 1) {
            setBasket((prevBasket) => [...prevBasket.slice(0, key), ...prevBasket.slice(key + 1)]);
        } else {
            setBasket((prevBasket) => prevBasket.map((item, index) => index === key && item.qty > 0 ? { ...item, qty: item.qty - 1 } : item));
        }
    };

    return (
        <div className={`navbar ${navbarColor && "colorActive"}`}>
            <div className="nav_items">
                <div className={`nav_item ${activeNavItem === 0 && 'active'}`} onClick={() => setActiveNavItem(0)}>
                    <FaStoreAlt />
                    <span className='item_txt'>Afhentning</span>
                </div>

                <div className={`nav_item ${activeNavItem === 1 && 'active'}`} onClick={() => setActiveNavItem(1)}>
                    <FaMotorcycle />
                    <span className='item_txt'>Levering</span>
                </div>

                <div className="nav_item clock">
                    <LuTimer />
                    <span className='item_txt'>{activeNavItem ? settings?.order_pickup_time : settings?.order_delivery_time}m</span>
                </div>
            </div>

            {basket.length > 0 && (
                <div className="nav_item color" onClick={(e) => basketMenuToggleFunc(e)}>
                    <MdOutlineShoppingBag />
                    <span className='item_txt'>Vis ordre</span>
                </div>
            )}

            <AnimatePresence>
                {basketMenuToggle && (
                    <motion.div className="basket" onClick={(e) => e.stopPropagation()}
                        initial={{ x: 400, opacity: 0.5 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 400, opacity: 0.5 }}
                        transition={{ duration: 0.3 }}
                    >
                        <IoClose className="close_icon" onClick={() => setBasketMenuToggle(false)} />

                        <div className="basket_container">
                            <h2 className="basket_title">Din indkøbskurv</h2>

                            {basket.length > 0 && basket.map((basketItem, basketItemKey) => (
                                <div className="basket_product" key={basketItemKey}>
                                    <div className="sec">
                                        <h3 className="product_name">{basketItem.name}</h3>
                                        <span className="product_price">{basketItem.price * basketItem.qty} kr.</span>
                                    </div>

                                    <div className="sec">
                                        <p className="product_extras">
                                            {basketItem.variants[0]?.option.name}
                                            {basketItem.extras?.map((extra) => ', ' + extra.name)}
                                        </p>

                                        <div className="piece">
                                            <button className="piece-btn" onClick={() => decreaseCount(basketItemKey)}>-</button>
                                            <p className="piece-label">{basketItem.qty}</p>
                                            <button className="piece-btn" onClick={() => increaseCount(basketItemKey)}>+</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="payment">
                            <Link to={`/checkout`} state={{ restaurantId: restaurantId, type: activeNavItem, restaurantName: restaurantName, restaurantAddres: restaurantAddres }}>
                                <button className="payment-btn link">Til Checkout - {basketTotalPrice} kr</button>
                            </Link>
                            <button className="payment-btn" onClick={() => { setBasket([]); setBasketMenuToggle(false); }}>Tøm kurv</button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Navbar;