import React, { useState } from 'react';
import ProductDetail from './ProductDetail';
import { AnimatePresence } from 'framer-motion';

const Menu = ({ menu, setBasket, basketMenuOpen }) => {
    const [productDetailToggle, setProductDetailToggle] = useState(false);
    const [categoryKey, setCategoryKey] = useState(null);
    const [productKey, setProductKey] = useState(null);

    const productDetailToggleFunc = (productKey, categoryKey) => {
        setCategoryKey(categoryKey);
        setProductKey(productKey);
        setProductDetailToggle(true);
    };

    return (
        <div className="menu">
            {menu?.categories?.map((categoryItem, categoryItemKey) => (
                categoryItem.is_active === 1 && categoryItem.products.length > 0 && (
                    <React.Fragment key={categoryItemKey}>

                        <div className="category_title" id={categoryItem.slug}>
                            <h2 className='title'>{categoryItem.name}</h2>
                            <h4 className='title_desc'>{categoryItem.description}</h4>
                        </div>

                        <div className="products_container" >
                            {categoryItem.products.map((productItem, productItemKey) => (
                                <React.Fragment key={productItemKey}>

                                    <div className="product_box" onClick={() => productDetailToggleFunc(productItemKey, categoryItemKey)}>
                                        <div className='product_box_content'>
                                            <h3 className='product_title'>{productItem.name}</h3>
                                            <p className='product_desc'>{productItem.description}description</p>
                                            <span className="product_price">{productItem.price} kr.</span>
                                        </div>

                                        {productItem.image === '' ?
                                            (<img src={productItem.image} alt="product" className="product_img" />) :
                                            (<img src="/img/productImage.jpeg" alt="product" className="product_img" />)
                                        }
                                    </div>

                                </React.Fragment>
                            ))}
                        </div>

                    </React.Fragment >
                )
            ))}

            <AnimatePresence>
                {productDetailToggle && (<ProductDetail product={menu?.categories[categoryKey]?.products[productKey]} setProductDetailToggle={setProductDetailToggle} setBasket={setBasket} />)}
            </AnimatePresence>
        </div >
    );
};

export default Menu;