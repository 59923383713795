import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";

const ProductDetail = ({ product, setProductDetailToggle, setBasket }) => {
    const [optionActiveIndex, setoptionActiveIndex] = useState(0);
    const [peace, setPeace] = useState(1);
    const [totalPrice, setTotalPrice] = useState(product.price + parseFloat(product.variants[0]?.options[optionActiveIndex].price || 0));
    const [selectedExtras, setSelectedExtras] = useState([]);
    const [note, setNote] = useState('');
    const [dropdownActive, setDropdownActive] = useState(0);

    const optionActiveIndexFunc = (optionKey) => {
        setoptionActiveIndex(optionKey);
        setTotalPrice(product.price + parseFloat(product.variants[0]?.options[optionKey].price || 0));
        document.querySelectorAll('input[type="checkbox"]:checked').forEach((checkbox) => checkbox.checked = false);;
        setSelectedExtras([]);
        setDropdownActive(0);
    };

    const selectedExtrasChangeFunc = (e, extraName, extraId, extraPrice, extraGroupKey, extraGroupLimit) => {
        const checkboxes = [];
        document.querySelectorAll(`input[name="${extraGroupKey}"]:checked`).forEach(checkbox => checkboxes.push(checkbox));

        if (checkboxes.length <= extraGroupLimit) {
            if (e.target.checked) {
                setSelectedExtras((prevSelectedExtras) => [...prevSelectedExtras, { name: extraName, id: extraId, price: extraPrice }]);
                setTotalPrice((prevTotal) => prevTotal + extraPrice);
            } else {
                setSelectedExtras((prevSelectedExtras) => prevSelectedExtras.filter((item) => !(item.name === extraName && item.id === extraId)));
                setTotalPrice((prevTotal) => prevTotal - extraPrice);
            }
        }
    };

    const selectedExtrasClickFunc = (e, extraGroupKey, extraGroupLimit) => {
        const checkboxes = [];
        document.querySelectorAll(`input[name="${extraGroupKey}"]:checked`).forEach(checkbox => checkboxes.push(checkbox));

        if (checkboxes.length > extraGroupLimit) {
            e.preventDefault();
        }
    };

    const addBasketFunc = () => {
        setBasket((prevBasket) => [
            ...prevBasket,
            {
                slug: product.slug,
                name: product.name,
                price: totalPrice,

                id: product.id,
                qty: peace,
                note: note,
                variants: product.variants.length > 0 ? [
                    {
                        variant: {
                            id: product.variants[0]?.id,
                            name: product.variants[0]?.name
                        },
                        option: product.variants[0].options.length > 0 ? {
                            id: product.variants[0]?.options[optionActiveIndex]?.id || null,
                            name: product.variants[0]?.options[optionActiveIndex]?.name || null
                        } : {}
                    }
                ] : [],
                extras: selectedExtras.length > 0 ? selectedExtras.map((extra) => ({
                    id: extra.id,
                    name: extra.name,
                    price: extra.price
                })) : []
            }
        ]);

        setProductDetailToggle(false);
    };

    return (
        <motion.div className='products_detail_container' onClick={() => setProductDetailToggle(false)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
        >

            <motion.div className="product_detail" onClick={(e) => e.stopPropagation()}
                initial={{ y: 200, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 200, opacity: 0 }}
                transition={{ duration: 0.3 }}
                layout
            >
                <button className="close_btn" onClick={() => setProductDetailToggle(false)}><IoClose /></button>

                {product.image === '' ?
                    (<img src={product.image} alt="product" className="product_image" />) :
                    (<img src="/img/productImage.jpeg" alt="product" className="product_image" />)
                }

                <div className="product_detail_content">
                    <div className="product_detail_title">
                        <h3 className='detail_title'>{product.name}</h3>
                        <p className='detail_desc'>{product.description}description</p>
                    </div>

                    <AnimatePresence>
                        {product.variants.length > 0 &&
                            <div className="variant_container">
                                {product.variants.map((variant, variantKey) => (
                                    <div key={variantKey}>
                                        {variant.options.length > 1 &&
                                            <div className="options_container">
                                                {variant.options.length > 0 && variant.options.map((option, optionKey) => (
                                                    option.extraGroups.length > 0 && (
                                                        <React.Fragment key={optionKey}>
                                                            <button className={`option_btn ${optionActiveIndex === optionKey && 'active'}`} onClick={() => optionActiveIndexFunc(optionKey)}>
                                                                {option.name} <span className='price'>{option.price} kr.</span>
                                                            </button>
                                                        </React.Fragment>
                                                    ))
                                                )}
                                            </div>
                                        }


                                        <div className="options_extraGroups_container">
                                            {variant.options.length > 0 && variant.options[optionActiveIndex].extraGroups.length > 0 &&
                                                variant.options[optionActiveIndex].extraGroups.map((extraGroup, extraGroupKey) => (
                                                    <div key={extraGroupKey}>
                                                        <div className='extraGroups_name' onClick={() => setDropdownActive(extraGroupKey)}>
                                                            <p className='txt'>
                                                                {extraGroup.name}
                                                                <span className='max_limit'>Max {extraGroup.limit}</span>
                                                            </p>
                                                            <IoIosArrowDown />
                                                        </div>


                                                        <AnimatePresence>
                                                            {dropdownActive === extraGroupKey &&
                                                                <motion.form className="extras_container"
                                                                    initial={{ opacity: 0, height: 0, y: "-50%", scaleY: 0 }}
                                                                    animate={{ opacity: 1, height: "auto", y: 0, scaleY: 1 }}
                                                                    exit={{ opacity: 0, height: 0, y: "-50%", scaleY: 0, transition: { duration: 0.2 } }}
                                                                    transition={{ type: "spring", duration: 0.5 }}
                                                                >
                                                                    {extraGroup.extras.map((extra, extraKey) => (
                                                                        <label className='extras' key={extraKey} >
                                                                            <input type="checkbox" name={extraGroupKey} onChange={(e) => selectedExtrasChangeFunc(e, extra.name, extra.id, extra.price, extraGroupKey, extraGroup.limit)} onClick={(e) => selectedExtrasClickFunc(e, extraGroupKey, extraGroup.limit)} />
                                                                            {extra.name}
                                                                            <span className='price'>{extra.price} kr.</span>
                                                                        </label>
                                                                    ))}
                                                                </motion.form>
                                                            }
                                                        </AnimatePresence>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>
                                ))}
                            </div>
                        }
                    </AnimatePresence>

                    <textarea id="noteContent" className='product_note' maxLength='70' resize='none' rows="3" cols="50" placeholder="Enter your note here..." value={note} onChange={(e) => setNote(e.target.value)} />

                    <div className="detail_payment">
                        <div className="detail_payment_content">
                            <button className="payment_content_btn" onClick={() => peace > 1 && setPeace(peace - 1)}>-</button>
                            <p className="payment_content_label">{peace}</p>
                            <button className="payment_content_btn" onClick={() => setPeace(peace + 1)}>+</button>
                        </div>
                        <button className="detail_payment_btn" onClick={addBasketFunc}>Tilfoj {totalPrice * peace} kr</button>
                    </div>
                </div>
            </motion.div>
        </motion.div >
    );
};

export default ProductDetail;