import React, { useState } from 'react';
import { motion } from "framer-motion";

//component
import Header from './Header';
import Footer from './Footer';

const MainLayout = ({ children, restaurantData }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <motion.div className='main_layout' onClick={() => setMenuOpen(false)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
        >
            <div className='children_container'>
                <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} restaurantData={restaurantData} />
                {children}
            </div>
            <Footer />
        </motion.div>
    );
};

export default MainLayout;