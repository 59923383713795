import React from 'react';

const SideBarLeft = ({ menu }) => {

    const ScrollIntoView = (id) => {
        document.querySelector('#' + id).scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <section className='sidebar_left'>
            <nav className='sidebar_items'>
                <ul className="sidebar_items_list">
                    {menu?.categories?.map((categoryItem, categoryItemKey) => (
                        categoryItem.is_active === 1 && categoryItem.products.length > 0 && (
                            <li className="list_item" onClick={() => ScrollIntoView(categoryItem.slug)} key={categoryItemKey}>
                                {categoryItem.name}
                            </li>)
                    ))}
                </ul>
            </nav>
        </section>
    );
};

export default SideBarLeft;

