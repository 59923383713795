import React from 'react';

/* Eksikler 
    backgroundImage
*/

const Slider = ({ name, address }) => {
    return (
        <section className='slider_container'>
            <div className='slider'
                style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)), url(/img/deneme.png)` }}>
                <h2 className='title'>{name}</h2>
                <h4 className='subtitle'>{address?.detail}</h4>
                <h5 className='subtitle'>{address?.post_code}</h5>
            </div>
        </section>
    );
};

export default Slider;