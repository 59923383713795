import React from 'react';
import MainLayout from '../../components/MainLayout';
import toast from 'react-hot-toast';
import { useQuery } from "@tanstack/react-query";

import { PostCompany } from "../../services";
import { Link } from 'react-router-dom';

const CompanyPage = () => {

    const { data, isLoading, error } = useQuery({
        queryFn: () => PostCompany(),
        queryKey: ['companyData'],
        onError: (error) => {
            toast.error(error.message);
            console.log(error);
        },
    });

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        console.error("Error fetching data:", error);
        return <p>Error fetching data</p>;
    }

    let companyData;
    if (data) {
        companyData = data.data;
    }

    return (
        <MainLayout>
            <div className="company_page" style={{ backgroundImage: `url(/img/restaurantImage.jpg)` }}>
                <h2 className="company_title">{companyData.name}</h2>

                <div className="restaurant_box_container">
                    {companyData.restaurants.map((restaurantData, restaurantKey) => (
                        restaurantData.is_active === 1 &&
                        (<Link to={`/${restaurantData.slug}`} className="restaurant_box" key={restaurantKey}>
                            <h3 className="box_title">{restaurantData.name}</h3>
                            <p className="box_desc">bu yok fklsjfldjl lkjfldsjfldsjlf</p>
                            <button className="box_btn">Bestil her</button>
                        </Link>)
                    ))}
                </div>
            </div>
        </MainLayout>
    );
};

export default CompanyPage;